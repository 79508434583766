<template>
  <div>
    <molecules-custom-dialog
      :show="showRemove"
      title="Apakah Anda Yakin?"
      :caption="`Anda akan menghapus proses b2b pada ${
        (showRemove && showRemove.client && showRemove.client.name) || '-perusahaan tidak ditemukan-'
      }`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => {
            showRemove = null
          },
        },
        {
          title: 'Iya Hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            removeB2B(showRemove)
            showRemove = null
          },
        },
      ]"
    />

    <v-slide-y-reverse-transition>
      <organism-salesb2b-filter-status v-if="showFilterStatus.open" v-model="showFilterStatus" />
    </v-slide-y-reverse-transition>

    <v-slide-y-reverse-transition>
      <organism-salesb2b-filter-stage v-if="showFilterStage.open" v-model="showFilterStage" />
    </v-slide-y-reverse-transition>

    <v-slide-y-reverse-transition>
      <organism-salesb2b-filter-items v-if="showFilter.open" v-model="showFilter" />
    </v-slide-y-reverse-transition>

    <v-slide-x-reverse-transition>
      <organism-salesb2b-conversation
        v-if="showConversation"
        :sales="showConversation"
        @closed="
          () => {
            showConversation = null
            this.getB2B()
            this.$vuetify.goTo(0)
          }
        "
      />
    </v-slide-x-reverse-transition>

    <v-slide-x-reverse-transition>
      <organism-salesb2b-assign
        v-if="showAssign || showEdit || showDetail"
        :id="showEdit || showDetail"
        :readOnly="showDetail ? true : false"
        @closed="
          () => {
            showAssign = null
            showEdit = null
            showDetail = null
          }
        "
      />
    </v-slide-x-reverse-transition>

    <v-slide-x-reverse-transition>
      <organism-clients-edit
        v-if="showClient"
        read-only
        :data="showClient"
        @closed="
          () => {
            showClient = null
          }
        "
      />
    </v-slide-x-reverse-transition>

    <v-row dense>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="check-all"
          :title="$idCurrency((summary && summary.total) || 0)"
          :cap="`Total Semua, ${(summary && summary.count) || 0} banyaknya`"
          :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="clipboard-list"
          :title="$idCurrency((summary && summary.totalProspect) || 0)"
          :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :cap="`Total Prospek, ${(summary && summary.countProspect) || 0} banyaknya`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="handshake-outline"
          color="green"
          :title="$idCurrency((summary && summary.totalWon) || 0)"
          :cap="` Total Won, ${(summary && summary.countWon) || 0} banyaknya`"
          :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="handshake"
          :title="$idCurrency((summary && summary.totalNegotiation) || 0)"
          :cap="` Total Negotiation, ${(summary && summary.countNegotiation) || 0} banyaknya`"
          :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="clipboard-list"
          color="green"
          :title="$idCurrency((summary && summary.totalProsesPKS) || 0)"
          :cap="` Total Proses PKS, ${(summary && summary.countProsesPKS) || 0} banyaknya`"
          :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="close-thick"
          color="red"
          :title="$idCurrency((summary && summary.totalLost) || 0)"
          :cap="` Total Lost, ${(summary && summary.countLost) || 0} banyaknya`"
          :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
    </v-row>

    <v-form @submit.prevent="loadData" id="filter">
      <v-row class="mt-5" dense>
        <v-col cols="11" md="auto" class="grow">
          <atoms-text-field v-if="!query.enabled || !isQueryClient" class="rounded-lg accent" noLabel>
            <v-text-field
              v-model.trim="query.term"
              @click:append="
                () => {
                  query = {
                    enabled: false,
                    value: '',
                    item: null,
                    term: '',
                  }
                  page = 1
                  loadData()
                }
              "
              @keydown.enter="
                () => {
                  loadData()
                  query.enabled = true
                }
              "
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
              placeholder="Ketikkan kata kunci pencarian..."
              appendIcon="mdi-close"
              class="rounded-lg ma-0 pa-0"
              hide-details
              flat
              solo
              dense
            >
              <template v-slot:prepend>
                <v-combobox
                  class="rounded-lg ma-0 pa-0"
                  placeholder="Query"
                  v-model="queryFilter"
                  :items="queryFilters"
                  item-text="title"
                  item-value="value"
                  hide-details
                  multiple
                  dense
                  solo
                  flat
                  required
                  outlined
                />
              </template>
            </v-text-field>
          </atoms-text-field>
          <atoms-text-field v-else label="Cari berdasarkan Client" :no-label="true">
            <v-row dense>
              <v-col cols="1">
                <atoms-button
                  icon
                  @click="
                    () => {
                      query = {
                        enabled: false,
                        value: '',
                        item: null,
                        term: '',
                      }
                      showFilter = {
                        open: false,
                        items: null,
                      }
                      showFilterStatus = {
                        open: false,
                        items: null,
                      }
                      showFilterStage = {
                        open: false,
                        items: null,
                      }
                      page = 1
                      loadData()
                    }
                  "
                >
                  <v-icon>mdi-delete</v-icon>
                </atoms-button>
              </v-col>
              <v-col cols="11">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="query.value"
                  :loading="$isArrayExists(this.query.items)"
                  :items="(query.items && this.query.items.map((x) => x.name)) || []"
                  :placeholder="` ${query.term ? `Pencarian Sekarang : ${query.term}.` : ''} Pilih Sales (Opsional)`"
                  :menu-props="{ offsetY: true }"
                  hide-details
                  dense
                  solo
                  flat
                />
              </v-col>
            </v-row>
          </atoms-text-field>
        </v-col>
        <v-col cols="1" md="auto" class="shrink order-md-last" align="end">
          <v-menu offset-x class="rounded-xl">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-list nav dense elevation="0" class="rounded-lg">
              <!-- <v-list-item
                @click="
                  () => {
                    query = {
                      enabled: false,
                      value: '',
                      item: null,
                      term: query.term,
                    }
                    isQueryClient = !isQueryClient
                  }
                "
                link
                class="rounded-lg px-5 primary white--text"
              >
                <v-icon left dark>mdi-database-search</v-icon>
                <v-list-item-title class="px-2"
                  >Set Query : {{ !isQueryClient ? 'Area' : 'Client' }}
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item @click="showAssign = true" link class="rounded-lg px-5">
                <v-icon left>mdi-map-marker-plus</v-icon>
                <v-list-item-title class="px-2">Tambah B2B</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2" align="end">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Sales</atoms-button
          >
        </v-col></v-row
      >
      <v-divider class="my-5"></v-divider>
      <v-row align="center" justify="center">
        <!-- <v-col cols="12" md="10">
          <atoms-text-field
            label="Cari Perusahaan"
            v-model.trim="term"
            :noLabel="true"
            :appendAction="
              () => {
                term = ''
                loadData()
              }
            "
            appendIcon="mdi-close"
          />
        </v-col>
        <v-col cols="12" md="2" class="d-flex justifyx-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari B2B Process</atoms-button
          >
        </v-col> -->
        <!-- <v-col cols="12" md="2" class="d-flex justifyx-space-between">
          <atoms-button
            @click="
              () => {
                showAssign = true
              }
            "
            style="width: 100%"
            class="primary lighten-5 primary--text"
            ><v-icon left>mdi-plus</v-icon>Tambah B2B</atoms-button
          >
        </v-col> -->

        <v-col cols="12" md="auto">
          <v-row dense align="center" justify="end">
            <v-col cols="12" md="auto">
              <atoms-text-field label="Stage" :noLabel="true">
                <v-combobox
                  class="rounded-lg"
                  placeholder="Sorter"
                  prepend-inner-icon="mdi-sort"
                  :items="sorters.map((x) => x.title)"
                  v-model="sorter"
                  @change="loadData"
                  hide-details
                  dense
                  solo
                  flat
                  required
                  :style="{
                    'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                  }"
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="12" md="auto">
              <atoms-button
                class="primary"
                :style="{
                  width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto',
                }"
                @click="
                  () => {
                    sort = sort === constant[0] ? constant[1] : constant[0]
                    getB2B()
                  }
                "
                ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
                >Sort: {{ sort }}</atoms-button
              ></v-col
            >
          </v-row>
        </v-col>
        <v-col cols="auto" class="d-flex gap-5 flex-wrap">
          <atoms-button @click="showFilterStatus.open = true" class="" outlined
            ><v-icon left>mdi-filter-variant</v-icon
            ><template #custom>
              <v-icon left>mdi-filter-variant</v-icon>
              Status
              <v-badge
                v-if="$isArrayExists(showFilterStatus.items)"
                color="primary"
                style="opacity: 0.8"
                class="pl-3"
                dot
                :title="`Filter Status : ${showFilterStatus.items.map((x) => x.title)}`"
              ></v-badge> </template
          ></atoms-button>
        </v-col>
        <v-col cols="auto">
          <atoms-button @click="showFilterStage.open = true" class="" outlined
            ><template #custom>
              <v-icon left>mdi-filter</v-icon>
              Stage
              <v-badge
                v-if="$isArrayExists(showFilterStage.items)"
                color="primary"
                style="opacity: 0.8"
                class="pl-3"
                dot
                :title="`Filter Stage : ${showFilterStage.items.map((x) => x.title)}`"
              ></v-badge> </template
          ></atoms-button>
        </v-col>
        <v-col cols="auto">
          <atoms-button @click="showFilter.open = true" class="" outlined
            ><template #custom>
              Filter
              <v-badge
                v-if="$isArrayExists(showFilter.items)"
                color="primary"
                style="opacity: 0.8"
                class="pl-3"
                dot
                :title="`Filter : ${showFilter.items.map((x) => x.title)}`"
              ></v-badge> </template
          ></atoms-button>
        </v-col>

        <v-col cols="12" md="auto" class="grow d-flex justify-center justify-md-end align-center">
          <v-checkbox v-model="enableDate" hide-spin-buttons /><atoms-text>Filter dengan tanggal pembuatan?</atoms-text>
        </v-col>

        <v-scroll-y-transition>
          <v-col order="last" cols="12" v-if="enableDate" class="my-0">
            <v-row dense>
              <v-col cols="12" lg="5">
                <atoms-date-picker
                  label="Dari Tanggal"
                  :modal="from.modal"
                  :range="30"
                  :value="from.value"
                  @change="
                    (modal, value) => {
                      from.modal = modal
                      from.value = value
                    }
                  "
                />
              </v-col>
              <v-col cols="12" lg="2" class="d-flex justify-center align-center">
                <atoms-text>Sampai Tanggal</atoms-text>
              </v-col>
              <v-col cols="12" lg="5">
                <atoms-date-picker
                  label="Sampai Tanggal"
                  :modal="to.modal"
                  :value="to.value"
                  :range="30"
                  @change="
                    (modal, value) => {
                      to.modal = modal
                      to.value = value
                    }
                  "
                />
              </v-col>
            </v-row>
          </v-col>
        </v-scroll-y-transition>
      </v-row>
    </v-form>

    <!-- core -->
    <v-container fluid class="px-0">
      <v-data-table
        class="rounded-lg display nowrap"
        disable-sort
        hide-default-header
        hide-default-footer
        mobile-breakpoint="0"
        :headers="headers"
        show-expand
        item-key="_id"
        :loading="mainLoading"
        :items="(!mainLoading && $store.state.salesb2b.data) || []"
      >
        <template v-slot:header="{ props }">
          <thead>
            <tr class="d-table-row">
              <th v-for="h in props.headers" :key="h.value" :class="['text-left', h.class]">
                <span class="primary--text text-uppercase">{{ h.text }}</span>
              </th>
            </tr>
          </thead></template
        >

        <template v-slot:[`item`]="{ item, index }">
          <tr class="text-left">
            <td>
              <v-icon
                @click="
                  () => {
                    {
                      const indexOfRow = expandedIds.indexOf(index)
                      if (indexOfRow > -1) {
                        expandedIds.splice(indexOfRow, 1)
                        return
                      }
                      expandedIds.push(index)
                    }
                  }
                "
                >mdi-chevron-{{ expandedIds.includes(index) ? 'up' : 'down' }}</v-icon
              >
            </td>
            <td class="text-center">
              <v-badge
                :value="(item.conversation && item.conversation.length) || 0"
                :color="
                  item.hadReadConversation && item.hadReadConversation.some((_id) => _id === $store.state.user._id)
                    ? 'primary'
                    : 'red darken-1'
                "
                :content="(item.conversation && item.conversation.length) || 0"
              >
                <v-icon
                  @click="
                    () => {
                      showConversation = item
                    }
                  "
                  >mdi-forum</v-icon
                >
              </v-badge>
            </td>
            <!-- item.areaId -->
            <td class="text-center">
              <template v-if="$isArrayExists(item.salesRepData)">
                <v-chip v-for="(x, i) in item.salesRepData || []" :key="i" class="rounded-full ma-1 px-1" small>
                  <v-avatar class="rounded-full" left
                    ><atoms-image :src="x.picture" style="object-fit: cover" class="rounded-full"
                  /></v-avatar>
                  {{ x.nickname }}</v-chip
                >
              </template>

              <span v-else>-</span>
            </td>
            <td>
              {{ (item.client && item.client.name) || '-' }}
            </td>
            <td class="text-center">
              {{ item.stage || '-' }}
            </td>
            <td class="text-center">
              {{ item.status || '-' }}
            </td>
            <td class="text-center">
              {{ item.client && item.client.isHkReg ? 'Internal' : 'External' }}
            </td>
            <td class="text-center">{{ item.jenisIndustri || '-' }}</td>
            <td class="text-center">
              {{ item._createdDate ? $moment(item._createdDate).format('DD MMMM YYYY') : '-' }}
            </td>
            <td class="text-center">
              {{ (item.updatedByData && `${item.updatedByData.nickname} - ${item.updatedByData.email}`) || '-' }}
            </td>
            <td class="text-center">
              {{ item.lastContacted ? $moment(item.lastContacted).format('DD MMMM YYYY') : '-' }}
            </td>
            <td class="text-center">
              {{ (item.manager && `${item.manager.nickname} - ${item.manager.email}`) || '-' }}
            </td>

            <td class="text-center">
              <v-menu offset-x class="rounded-xl">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list nav dense elevation="0" class="text-center rounded-lg">
                  <v-list-item link @click="showDetail = item._id" class="rounded-lg px-4 text-center">
                    <v-icon left>mdi-account-details</v-icon>
                    <v-list-item-title class="px-2">Detail</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-show="item.client && item.client._id"
                    link
                    @click="
                      $router.push(
                        `/invoices/review?client=${item.client._id}${
                          (enableDate && `&from=${from.value}&to=${to.value}`) || ''
                        }`,
                      )
                    "
                    class="rounded-lg px-4 text-center"
                  >
                    <v-icon left>mdi-stack-overflow</v-icon>
                    <v-list-item-title class="px-2">Invoice</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    :disabled="item && !item._id"
                    link
                    @click="showEdit = item && item._id"
                    class="rounded-lg px-4 text-center"
                  >
                    <v-icon left>mdi-pencil</v-icon>
                    <v-list-item-title class="px-2">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    v-if="$store.state.user.roles.includes($roles.Super_Admin)"
                    @click="showRemove = item"
                    class="rounded-lg px-4 text-center red white--text"
                  >
                    <v-icon left dark>mdi-delete</v-icon>
                    <v-list-item-title class="px-2">Hapus</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="showClient = item.client" link class="rounded-lg px-4 text-center">
                    <v-icon left>mdi-domain</v-icon>
                    <v-list-item-title class="px-2">Detail Perusahaan</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          <tr v-if="expandedIds.includes(index)">
            <td :colspan="headers.length" class="pa-0">
              <v-simple-table>
                <thead>
                  <tr class="d-table-row">
                    <th
                      v-for="(header, i) in [
                        { title: 'kebutuhan' },
                        { title: 'upah phl' },
                        { title: 'penawaran ke klien' },
                        { title: 'total penawaran ' },
                        { title: 'position ' },
                        { title: 'pic name ' },
                        { title: 'phone ' },
                        { title: 'mobile ' },
                        { title: 'pic email ' },
                      ]"
                      :key="i"
                      :class="['text-center', header.class]"
                    >
                      <span :class="['orange--text', 'text-uppercase']">{{ header.title }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      {{ $idCurrency(item.kebutuhan || 0, 0, 0) }}
                    </td>
                    <td class="text-center">
                      {{ $idCurrency(item.upah || 0, 0, 0) }}
                    </td>
                    <td class="text-center">
                      {{ $idCurrency(item.penawaran || 0, 0, 0) }}
                    </td>
                    <td class="text-center">
                      {{ $idCurrency(item.kebutuhan * +item.penawaran || 0, 0, 0) }}
                    </td>
                    <td class="text-center">
                      {{ item.posisi || '-' }}
                    </td>
                    <td class="text-center">
                      {{ item.namaPIC || '-' }}
                    </td>
                    <td class="text-center">
                      {{ item.phonePIC || '-' }}
                    </td>
                    <td class="text-center">
                      {{ item.mobilePIC || '-' }}
                    </td>
                    <td class="text-center">
                      {{ item.emailPIC || '-' }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-divider></v-divider>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td colspan="12" :class="[item.remarks && 'py-2']">
                      <atoms-text
                        ><span class="font-weight-bold">Remarks :</span>
                        {{ item.remarks || '-' }}
                      </atoms-text>
                    </td>
                  </tr>
                  <!-- <tr>
                      <td colspan="12">
                        <atoms-text
                          ><span class="font-weight-bold">Last Contact :</span>
                          {{
                            (item.lastContacted &&
                              $moment(item.lastContacted)
                                .locale("en")
                                .format("DD/MM/YYYY, HH:mm:ss A")) ||
                            "-"
                          }}
                        </atoms-text>
                      </td>
                    </tr> -->
                  <tr>
                    <td colspan="12">
                      <atoms-text
                        ><span class="font-weight-bold">Diperbarui Oleh :</span>
                        {{
                          (item.updatedByData && `${item.updatedByData.nickname} - ${item.updatedByData.email}`) || '-'
                        }}
                      </atoms-text>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="12">
                      <atoms-text
                        ><span class="font-weight-bold">Tanggal Pembuatan :</span>
                        {{
                          (item._createdDate &&
                            $moment(item._createdDate).locale('en').format('DD/MM/YYYY, HH:mm:ss A')) ||
                          '-'
                        }}
                      </atoms-text>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="12">
                      <atoms-text
                        ><span class="font-weight-bold">Terakhir Disunting :</span>
                        {{
                          (item._updatedDate &&
                            $moment(item._updatedDate).locale('en').format('DD/MM/YYYY, HH:mm:ss A')) ||
                          '-'
                        }}
                      </atoms-text>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-row
        justify="center"
        v-if="$isArrayExists($store.state.salesb2b.data) && $store.state.salesb2b.pagesLength && !mainLoading"
      >
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination class="my-4" v-model="page" :length="$store.state.salesb2b.pagesLength"></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
const sortConstant = [
  {
    title: 'last contacted',
    value: 'lastContacted',
  },
  {
    title: 'sales',
    value: 'sales.nickname',
  },
  {
    title: 'perusahaan',
    value: 'client.name',
  },
  {
    title: 'stage',
    value: 'stage',
  },
  {
    title: 'status',
    value: 'status',
  },
  {
    title: 'internal/ eksternal',
    value: 'client.isHkReg',
  },
  {
    title: 'jenis industri',
    value: 'jenisIndustri',
  },
  {
    title: 'kebutuhan',
    value: 'kebutuhan',
  },
  {
    title: 'upah',
    value: 'upah',
  },
  {
    title: 'penawaran',
    value: 'penawaran',
  },
  {
    title: 'total penawaran',
    value: 'totalPenawaran',
  },
  {
    title: 'posisi',
    value: 'posisi',
  },
  {
    title: 'nama pic',
    value: 'namaPIC',
  },
  {
    title: 'phone pic',
    value: 'phonePIC',
  },
  {
    title: 'mobile pic',
    value: 'mobilePIC',
  },
  {
    title: 'email pic',
    value: 'emailPIC',
  },
  {
    title: 'remarks',
    value: 'remarks',
  },
  {
    title: 'manager name',
    value: 'managerName',
  },
  {
    title: 'updated by name',
    value: 'updatedByName',
  },
  {
    title: 'updated date',
    value: '_updatedDate',
  },
  {
    title: 'created date',
    value: '_createdDate',
  },
]
export default {
  data() {
    return {
      page: 1,
      limit: 10,

      expandedIds: [],
      reportLoading: false,
      mainLoading: false,
      enableDate: false,
      showFilter: {
        open: false,
        items: null,
      },
      showFilterStatus: {
        open: false,
        items: null,
      },
      showFilterStage: {
        open: false,
        items: null,
      },
      isQueryClient: false,
      queryFilter: null,
      query: {
        term: '',
        value: '',
        item: null,
        items: null,
        enabled: false,
      },
      queryFilters: [
        {
          title: 'Nama Perusahaan',
          value: (term) => ({
            'client.name': {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },
        {
          title: 'Email PIC',
          value: (term) => ({
            emailPIC: {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },

        {
          title: 'Nama PIC',
          value: (term) => ({
            namaPIC: {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },
        {
          title: 'Jenis Industri',
          value: (term) => ({
            jenisIndustri: {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },
        {
          title: 'Nomor PIC',
          value: (term) => ({
            phonePIC: {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },
        {
          title: 'Posisi',
          value: (term) => ({
            posisi: {
              $regex: term || '',
              $options: 'i',
            },
          }),
        },
        // {
        //   priority: 0,
        //   title: 'Nama Sales',
        //   value: (term) => ({
        //     'salesRepData.nickname': {
        //       $regex: term || '',
        //       $options: 'i',
        //     },
        //   }),
        // },
      ],
      sorter: '',
      sort: '',
      constant: ['Desc', 'Asc'],
      sorters: sortConstant?.map((x) => {
        return {
          title: this.$toCapitalize(x.title),
          value: x.value,
        }
      }),
      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },

      showClient: null,
      showDetail: null,
      showAssign: null,
      showRemove: null,
      showEdit: null,
      showConversation: null,
      summary: {},

      headers: [
        {
          text: '',
          class: 'text-center',
          value: 'data-table-expand',
        },
        {
          text: '',
          class: 'text-center',
          width: 'auto',
        },
        {
          text: 'Sales',
          class: 'text-center',
        },
        {
          text: 'Nama Perusahaan',
          class: 'text-center',
        },
        {
          text: 'Stage',
          class: 'text-center',
        },
        {
          text: 'Status',
          class: 'text-center',
        },
        {
          text: 'Internal/External',
          class: 'text-center',
        },
        {
          text: 'Jenis Industri',
          class: 'text-center',
        },
        {
          text: 'tanggal dibuat',
          class: 'text-center',
        },
        {
          text: 'disunting oleh',
          class: 'text-center',
        },
        {
          text: 'last contacted',
          class: 'text-center',
        },
        {
          text: 'Manager/CreatedBy',
          class: 'text-center',
        },
        {
          text: '',
          class: 'text-center',
          width: 'auto',
        },
      ],
    }
  },
  watch: {
    enableDate: function (newValue) {
      this.loadData()
    },
    'query.enabled': async function (newValue) {
      if (!newValue) {
        return
      }

      this.query.items = await this.$store.dispatch('salesb2b/getAll', {
        custom: {
          pipeline: [
            {
              $match: {
                salesRep: { $exists: true },
              },
            },
            {
              $unwind: {
                path: '$salesRep',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $lookup: {
                from: 'Users',
                localField: 'salesRep',
                foreignField: '_id',
                as: 'salesRepData',
              },
            },
            {
              $unwind: {
                path: '$salesRepData',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $project: {
                _id: 'salesRepData._id',
                nickname: 'salesRepData.nickname',
              },
            },
          ],
        },
        limit: 1,
        returnOnly: true,
      })
    },
    'from.value': function (newValue) {
      if (newValue) {
        this.loadData()
      }
    },
    'to.value': function (newValue) {
      if (newValue) {
        this.loadData()
      }
    },
    'showFilterStatus.open': function (newValue) {
      if (newValue == false) {
        this.loadData()
      }
    },
    'showFilterStage.open': function (newValue) {
      if (newValue == false) {
        this.loadData()
      }
    },
    'showFilter.open': function (newValue) {
      if (newValue == false) {
        this.loadData()
      }
    },
    page: {
      handler() {
        this.getB2B()
      },
      deep: true,
    },
  },
  computed: {
    formatFromDate: {
      set(value) {
        this.from.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.from.value).format('DD MMMM YYYY')
      },
    },
    formatToDate: {
      set(value) {
        this.to.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.to.value).format('DD MMMM YYYY')
      },
    },
  },
  async mounted() {
    this.sort = this.constant[0]
    this.sorter = this.$toCapitalize(sortConstant[0].title)
    await this.getSummary()
    this.loadData()
  },
  methods: {
    async loadData() {
      this.$store.commit('SET', {
        loading: true,
      })
      this.page = 1
      // this.query.enabled = true
      this.expandedIds = []
      await this.getB2B()

      this.$vuetify.goTo(0)
      this.$store.commit('SET', {
        loading: false,
      })
    },
    pipeline() {
      let queryFiltersNonPriority = this.queryFilters?.filter((x) => x.priority === undefined)
      let queryFiltersPriority = this.queryFilters?.filter((x) => x.priority !== undefined)
      return [
        {
          $lookup: {
            from: 'Clients',
            localField: 'klien',
            foreignField: '_id',
            as: 'client',
          },
        },
        {
          $unwind: {
            path: '$client',
            preserveNullAndEmptyArrays: true,
          },
        },
        {
          $sort: Object.assign(
            {},
            (() => {
              const finder = this.sorters?.find((x) => x.title === this.sorter)?.value
              return finder
                ? {
                    [finder]: this.sort === this.constant[0] ? -1 : 1,
                  }
                : {
                    _createdDate: this.sort === this.constant[0] ? -1 : 1,
                  }
            })(),
          ),
        },
        {
          $match: {
            _createdDate: this.enableDate
              ? {
                  $gte: new Date(new Date(this.from.value).setHours(0, 0, 0, 0)),
                  $lt: new Date(new Date(this.to.value).setHours(45, 0, 0, 0)),
                }
              : { $exists: true },

            $and: [
              {
                $or: [
                  ...(this.queryFilter?.length > 0
                    ? this.queryFilter?.map((x) =>
                        queryFiltersNonPriority?.find((y) => y.title === x.title)?.value?.(this.query.term),
                      )
                    : queryFiltersNonPriority.map((x) => x?.value?.(this.query.term))),
                ],
              },
              {
                ...(this.showFilterStatus.items?.length > 0
                  ? { $or: this.showFilterStatus.items?.map((x) => x?.value) }
                  : {}),
              },
              {
                ...(this.showFilterStage.items?.length > 0
                  ? { $or: this.showFilterStage.items?.map((x) => x?.value) }
                  : {}),
              },
            ],

            ...(this.showFilter.items?.length > 0
              ? Object.assign({}, ...this.showFilter.items?.map((x) => x?.value))
              : []),

            // ...(this.showFilterStatus.items?.length > 0
            //   ? Object.assign({}, ...this.showFilterStatus.items?.map((x) => x?.value))
            //   : []),
            // ...(this.showFilterStage.items?.length > 0
            //   ? Object.assign({}, ...this.showFilterStage.items?.map((x) => x?.value))
            //   : []),
            // ...(this.showFilter.items?.length > 0
            //   ? Object.assign({}, ...this.showFilter.items?.map((x) => x?.value))
            //   : []),
          },
        },

        {
          $lookup: {
            from: 'Users',
            localField: 'salesRep',
            foreignField: '_id',
            as: 'salesRepData',
          },
        },
        {
          $lookup: {
            from: 'Users',
            localField: 'updatedBy',
            foreignField: '_id',
            as: 'updatedByData',
          },
        },
        {
          $lookup: {
            from: 'Users',
            localField: 'managerId',
            foreignField: '_id',
            as: 'manager',
          },
        },
        {
          $unwind: {
            path: '$updatedByData',
            preserveNullAndEmptyArrays: true,
          },
        },
        {
          $unwind: {
            path: '$manager',
            preserveNullAndEmptyArrays: true,
          },
        },

        // {
        //   $addFields: {
        //     totalPenawaran: {
        //       $multiply: [
        //         {
        //           $toInt: {
        //             $cond: {
        //               if: { $ne: ['$penawaran', ''] },
        //               then: '$penawaran',
        //               else: 0,
        //             },
        //           },
        //         },
        //         {
        //           $toInt: {
        //             $cond: {
        //               if: { $ne: ['$kebutuhan', ''] },
        //               then: '$kebutuhan',
        //               else: 0,
        //             },
        //           },
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          $project: {
            'salesRepData._id': 1,
            'salesRepData.nickname': 1,
            'salesRepData.picture': 1,
            'client._id': 1,
            'client.name': 1,
            'client.isHkReg': 1,
            stage: 1,
            status: 1,
            jenisIndustri: 1,
            kebutuhan: 1,
            upah: 1,
            penawaran: 1,
            // totalPenawaran: 0,
            posisi: 1,
            namaPIC: 1,
            phonePIC: 1,
            mobilePIC: 1,
            emailPIC: 1,
            remarks: 1,
            lastContacted: 1,
            'manager._id': 1,
            'manager.nickname': 1,
            'manager.picture': 1,
            'manager.email': 1,
            'updatedByData._id': 1,
            'updatedByData.nickname': 1,
            'updatedByData.email': 1,
            _updatedDate: 1,
            _createdDate: 1,
            conversation: 1,
            hadReadConversation: 1,
          },
        },
        // {
        //   $match: {
        //     $or: [
        //       ...(this.queryFilter?.length > 0
        //         ? this.queryFilter?.map((x) =>
        //             queryFiltersPriority?.find((y) => y.title === x.title)?.value?.(this.query.term),
        //           )
        //         : queryFiltersPriority.map((x) => x?.value?.(this.query.term))),
        //     ],
        //   },
        // },
      ]
    },
    async removeB2B(payload) {
      this.$store.commit('SET', { loading: true })
      try {
        if (payload?._id) {
          await this.$store.dispatch('salesb2b/remove', payload?._id)
          this.$showDialog({
            title: 'Berhasil',
            body: `Proses B2B berhasil dihapus!`,
          })
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getSummary() {
      this.reportLoading = true
      try {
        const queryString = JSON.stringify({
          pipeline: this.pipeline(),
        })
        this.summary = await this.$api
          .get('SalesB2B/getSum', {
            params: {
              jsonQuery: queryString,
            },
          })
          .then((res) => res?.result || {})
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.reportLoading = false
      }
    },
    async getB2B() {
      this.mainLoading = true
      try {
        console.log(this.pipeline())
        await this.$store.dispatch('salesb2b/getAll', {
          custom: {
            pipeline: this.pipeline(),
          },
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
  },
}
</script>
